/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { MutualFundsTimeFrameSimulationValues, mutualFundsTimeFrameValidationSchema, useMutualFundsTimeFrame } from './useMutualFundsTimeFrame';
import { InputField, NumericFormatField, SelectField } from '../../Forms/Fields';
import { DISCOVER_INVESTMENT_PROFILE_URL, MutualFundsGoals, RiskProfiles } from '../../../utils/constants';

export function MutualFundsTimeFrame() {
  const [repeatSimulation, setRepeatSimulation] = useState(false);

  const methods = useForm<MutualFundsTimeFrameSimulationValues>({
    defaultValues: {
      other_goal: '',
      risk_profile: RiskProfiles.asSelectOptionsWithPercentage('ffmm').defaultChoice,
    },
    resolver: zodResolver(mutualFundsTimeFrameValidationSchema),
  });

  const goal_type = methods.watch('goal_type');

  const { handleSubmitSimulation } = useMutualFundsTimeFrame();

  const handleTriggerErrors = () => methods.formState.errors;
  const onSubmit = async (values: MutualFundsTimeFrameSimulationValues) => {
    await handleSubmitSimulation(values);
    setRepeatSimulation(true);
  };

  return (
    <FormProvider {...methods}>
      <form className="d-flex flex-column gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <SelectField<MutualFundsTimeFrameSimulationValues>
          id="goal_type"
          path="goal_type"
          label="¿Cuál es tu meta?"
          placeholder="Seleccionar"
          options={MutualFundsGoals.asSelectOptions()}
          onChange={(option) => option?.value !== 'OTHER' && methods.setValue('other_goal', '')}
        />

        {goal_type?.value === 'OTHER' && (
          <InputField<MutualFundsTimeFrameSimulationValues>
            id="other_goal"
            label="Escribe cuál es tu meta"
            path="other_goal"
          />
        )}

        <NumericFormatField<MutualFundsTimeFrameSimulationValues>
          id="goal"
          label="¿Cuál es el valor de tu meta?"
          path="goal"
          placeholder="$"
          prefix="$ "
        />

        <NumericFormatField<MutualFundsTimeFrameSimulationValues>
          id="monthly_contribution"
          label="¿Cuánto puedes ahorrar mensualmente?"
          path="monthly_contribution"
          placeholder="$"
          prefix="$ "
        />

        <SelectField<MutualFundsTimeFrameSimulationValues>
          id="risk_profile"
          path="risk_profile"
          renderLabel={() => (
            <span>
              {'¿Cuál es tu perfil inversionista? '}
              <span className="fw-normal">
                {'Si no sabes revisa '}
              </span>
              <a href={DISCOVER_INVESTMENT_PROFILE_URL} target="_blank" className="fw-bold" rel="noreferrer">aquí</a>
            </span>
          )}
          options={
            RiskProfiles
              .asSelectOptionsWithPercentage<MutualFundsTimeFrameSimulationValues>('ffmm')
              .choices
            }
        />

        <button onClick={handleTriggerErrors} type="submit" className="btn btn-secondary text-white py-3 w-100 fw-bold mt-4">
          {repeatSimulation ? 'Volver a simular' : 'Simular'}
        </button>
      </form>
    </FormProvider>
  );
}
