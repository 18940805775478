/*
  In order to ease forms validations we are using Zod library; which provides a lot of
  shapes and objects validations constructors.

  Ideally, all the applications forms validations should be build over this library in order to
  mantain consistency through the code.

  Content:
    - Generic validation dispatcher => Standard form validation handler
    - Atomic validations => Atomic functions for validate specific fields and patterns
    - Errors handling => Functions for automate errors handling
*/

import { z } from 'zod';
import { DATE_FORMAT, getRawYearsDiffFromNow, getYearsDiffFromNow } from './dates';
import {
  GenderOptionType, SavingsOption, ApvTransferOptions,
  StringifiedBooleans,
  AdditionalSavingsOptionsType,
} from './constants';

type ValidationMode = 'EXCLUSIVE' | 'INCLUSIVE';

// GENERIC VALIDATION DISPATCHER
// ==================================================

export function validateForm<T extends z.ZodRawShape>(
  values: unknown, schema: z.ZodEffects<z.ZodObject<T>> | z.ZodObject<T>) {
  return schema.parse(values);
}

// ATOMIC VALIDATIONS
// ==================================================

export function validateRut(value: string) {
  const rut = value
    .replace(/-/g, '')
    .replace(/\./g, '');

  if (rut.length < 2) {
    return false;
  }

  const digits = rut.slice(0, -1);
  const dv = rut.slice(-1).toUpperCase();

  // calculate DV
  let sum = 0;
  let mult = 2;

  for (let i = digits.length - 1; i >= 0; i -= 1) {
    sum += parseInt(rut[i], 10) * mult;
    mult = (mult + 1) % 8 || 2;
  }

  // check DV
  switch (sum % 11) {
    case 1: return dv === 'K';
    case 0: return dv === '0';
    default: return `${11 - (sum % 11)}` === dv;
  }
}

export function validateRutByRegexp(value: string) {
  // For full format XXX.XXX.XXX-Y
  const fullFormat = /^([1-9]\d{0,2}(\.\d{3}){1,2}-[\dkK])$/;
  // For incomplete format XXXXXXXXXY
  const incompleteFormat = /^([1-9]\d{0,2}(\d{3}){1,2}[\dkK])$/;

  // A valid rut is a string which matches `fullFormat` or `incompleteFormat`
  const isValidFormat = fullFormat.test(value) || incompleteFormat.test(value);
  const isValidValidatorDigit = validateRut(value);

  return isValidFormat && isValidValidatorDigit;
}

export function validateAgeMayority(date: string, format = DATE_FORMAT) {
  const years = getYearsDiffFromNow(date, format);

  return years >= 18;
}

export function validateAgeMinority(date: string, format = DATE_FORMAT) {
  const years = getYearsDiffFromNow(date, format);

  return years < 18;
}

interface ValidateMaxAgeOptions {
  date: string,
  maxAge?: number
  format?: string
  mode?: ValidationMode
}

const DEFAULT_MAX_AGE = 100;
const DEFAULT_VALIDATION_MODE: ValidationMode = 'INCLUSIVE';
export const MAX_AGE_BY_GENDER: Record<GenderOptionType, number> = {
  MALE: 69,
  FEMALE: 69,
  OTHER: 69,
};

export const MIN_RETIREMENT_AGE_BY_GENDER: Record<GenderOptionType, number> = {
  MALE: 66,
  FEMALE: 61,
  OTHER: 61,
};

export function validateMaxAge({
  date,
  format = DATE_FORMAT,
  maxAge = DEFAULT_MAX_AGE,
  mode = DEFAULT_VALIDATION_MODE,
}: ValidateMaxAgeOptions) {
  const years = getRawYearsDiffFromNow(date, format) * -1;

  return mode === 'INCLUSIVE' ? years <= maxAge : years < maxAge;
}

export function validateMaxAgeByGender(date: string, gender: GenderOptionType) {
  return validateMaxAge({ date, maxAge: MAX_AGE_BY_GENDER[gender], mode: 'EXCLUSIVE' });
}

export function validateNonFutureDate(date: string, format = DATE_FORMAT) {
  return getRawYearsDiffFromNow(date, format) <= 0;
}

export function validateAPVTransfer(
  contributionOption: keyof typeof ApvTransferOptions,
  contributionAmount: number,
  transferCompany?: string
) {
  // No validate forms which dont have initial contribution selected
  if (contributionOption === 'NONE') {
    return true;
  }

  // If selected option is `TRANSFER`, ensure the given amount is greater than 0
  return (transferCompany && transferCompany.length > 0) && contributionAmount > 0;
}

export function validateHasRetirementAge(
  changeRetirementAge: keyof typeof StringifiedBooleans,
  retirementAge: number | null
) {
  // If `change_retirement_age` is selected; verify if given `retirement_age` is greater than 0
  if (changeRetirementAge === 'TRUE') {
    return retirementAge && retirementAge > 0;
  }

  // If `change_retirement_age` is not selected; just no validate
  return true;
}

/**
 * Checks if given retirement age is valid based on the selected gender
 */
export function validateMinimumRetirementAge(
  retirementAge: number | null,
  gender: GenderOptionType) {
  // Bypass validation if retirement age is not provided
  if (!retirementAge) return true;

  return retirementAge >= MIN_RETIREMENT_AGE_BY_GENDER[gender];
}

/**
 * Checks if given retirement age is greater than provided birthdate
 */
export function validateRetirementAgeAgainstBirthdate(
  retirementAge: number | null,
  birthdate: string
) {
  // Bypass validation if retirement age is not provided
  if (!retirementAge) return true;

  const birthdateYears = getYearsDiffFromNow(birthdate);
  return retirementAge >= birthdateYears;
}

/**
 * Checks if current age is greater than default retirement age custom retirement age
 * is not provided
 */
export function forceRetirementAgeByBirthdate(
  birthdate: string,
  gender: GenderOptionType,
  hasRetirementAge: keyof typeof StringifiedBooleans
) {
  const birthdateYears = getYearsDiffFromNow(birthdate);

  // Bypass if birthdate years does not exceed default max gender age
  if (birthdateYears < MIN_RETIREMENT_AGE_BY_GENDER[gender]) {
    return true;
  }

  // Checks if user forced retirement age, if not, validation fails
  return hasRetirementAge === 'TRUE';
}

/** Checks if selected saving option is `goal value` and validates the time frame
 *  If savings option is other than `goal value`; bypass validation.
 */
export function validateTimeFrameForGoal(
  savingsOption: keyof typeof SavingsOption,
  timeFrame: number,
  minTimeFrame: number
) {
  if (savingsOption === SavingsOption.savings_capacity) {
    return true;
  }

  return savingsOption === 'goal_value' && timeFrame >= minTimeFrame;
}

export function validateSavingsOption(
  selectedOption: keyof typeof SavingsOption,
  expectedOption: keyof typeof SavingsOption,
  amount: number
) {
  // Only validate amount against selected option
  if (selectedOption === expectedOption) {
    return amount > 0;
  }

  return true;
}

export function validateHasOtherInvestments(
  hasOtherInvestments: string,
  investments: AdditionalSavingsOptionsType[]
) {
  if (hasOtherInvestments === StringifiedBooleans.TRUE) {
    return investments.length > 0;
  }

  return true;
}

export function validateHasIdealPension(
  savingsOption: keyof typeof SavingsOption,
  idealPensionAmount: number
) {
  if (savingsOption === 'ideal_pension') {
    return idealPensionAmount > 0;
  }

  return true;
}

export const MIN_AGREED_PREMIUM_UF = 2.2;
export function validateHasAgreedPremium(
  savings_option: keyof typeof SavingsOption,
  agreedPremiumAmount: number
) {
  if (savings_option === 'savings_capacity') {
    return agreedPremiumAmount >= MIN_AGREED_PREMIUM_UF;
  }

  return true;
}

export function validateLongevityObjective(
  objective: string,
  longevityObjective: string
) {
  if (objective === 'LONGEVITY') {
    return longevityObjective.trim() !== '';
  }
  return true;
}

export function validateAssetManagementByNetWorth(
  netWorth: number,
  minNetWorth: number,
  wantsToDecide?: string | null,
) {
  if (netWorth >= minNetWorth) {
    return Boolean(wantsToDecide);
  }

  return true;
}

// ERRORS HANDLING
// ==================================================

// These functions only works in a vanilla JS/TS DOM manipulation
// If you are working in REACT paradigm, this function should be avoided
export function displayErrors(errors: z.ZodIssue[]) {
  errors.forEach((e) => {
    const field = e.path as unknown as string;

    /*
      Notice if we want to display error messages for a particular input
      we must create the error container element with the following id pattern: `<fieldname>-error`

      In this way, `displayErrors` will be able to reach and identify the error container and will
      display the error message associated to that field
    */
    const errorContainer = document.querySelector(`#${field}-error`);

    if (errorContainer) {
      errorContainer.textContent = e.message;
    }
  });
}

// These functions only works in a vanilla JS/TS DOM manipulation
// If you are working in REACT paradigm, this function should be avoided
export function clearErrors() {
  /*
    Notice if we want to clear the display errors, each error container element must apply
    the `error` class.

    In this way, `clearErrors` will be able to reach and identify the error container and will clear
    up the previous error message
  */
  const errorElements = document.querySelectorAll('.error');

  /*
    Param reassing is needed in order to mutate the element internal text content and clear
    the previous error message
  */
  // eslint-disable-next-line no-param-reassign
  errorElements.forEach((element) => { element.textContent = ''; });
}
