import React, { MutableRefObject, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InvestmentFormValues, MIN_NET_WORTH_FOR_ASSET_MANAGEMENT, saveOnComesFromAssetAdvisory } from './useInvestmentForm';
import { ButtonLoading } from '../../common/ButtonLoading';
import {
  DatepickerField, InputField, NumericFormatField, RadioField,
} from '../../Forms/Fields';
import {
  BooleanOptions, InvestmentProfiles,
} from '../../../utils/constants';
import { validateAssetManagementByNetWorth } from '../../../utils/validations';
import { injectCleanedFieldToForm } from '../../../utils/forms';

interface ClientSectionProps {
  handleNextStep: () => void;
  formRef: MutableRefObject<HTMLFormElement | null>
  showObjectivesSection?: boolean;
}

export function ClientSection({
  handleNextStep,
  formRef,
  showObjectivesSection,
}: ClientSectionProps) {
  const form = useFormContext<InvestmentFormValues>();
  const {
    date_of_birth,
    has_children,
    net_worth,
    profile,
    has_usd_stocks,
    wants_to_decide,
    name,
  } = form.watch();
  const isFilled = [
    name,
    date_of_birth,
    has_children,
    net_worth,
    profile,
    has_usd_stocks,
  ].every(Boolean) && validateAssetManagementByNetWorth(
    net_worth,
    MIN_NET_WORTH_FOR_ASSET_MANAGEMENT,
    wants_to_decide);

  const isLowNetWorth = net_worth < MIN_NET_WORTH_FOR_ASSET_MANAGEMENT;

  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const fakeDelay = 1000; // 1 second

  const triggerFakeLoading = () => {
    setIsFakeLoading(true);

    setTimeout(() => {
      setIsFakeLoading(false);
    }, fakeDelay);
  };

  const handleNavigateToAssetAdvisory = () => {
    saveOnComesFromAssetAdvisory();

    if (formRef.current) {
      injectCleanedFieldToForm({ form: formRef.current, name: 'net_worth', value: net_worth });
      injectCleanedFieldToForm({ form: formRef.current, name: 'action', value: 'assets' });
      formRef.current.submit();
    }
  };

  useEffect(() => {
    if (isLowNetWorth) {
      form.setValue('wants_to_decide', undefined);
    }
  }, [isLowNetWorth]);

  return (
    <section className="card-form">
      <h2 className="fs-base text-subtitle mb-0">Conociendo a mi cliente</h2>

      <InputField<InvestmentFormValues>
        id="name"
        path="name"
        label="¿Cuál es tu nombre?"
        placeholder="Ingresar nombre"
      />
      <DatepickerField<InvestmentFormValues>
        id="date_of_birth_field"
        path="date_of_birth"
        label="¿Cuál es tu fecha de nacimiento?"
      />
      <RadioField<InvestmentFormValues>
        path="has_children"
        label="¿Tienes hijos?"
        options={BooleanOptions.asRadioOptions('lowercase').reverse()}
        optionsDirection="vertical"
      />
      <NumericFormatField<InvestmentFormValues>
        id="net_worth"
        path="net_worth"
        label="¿Cuánto es tu patrimonio invertible?"
        prefix="$ "
        placeholder="$"
      />
      <RadioField<InvestmentFormValues>
        path="profile"
        label={(
          <>
            ¿Cuál es tu perfil inversionista?
            {' '}
            <span className="fw-normal">Si no sabes revisa</span>
            {' '}
            <a href="https://banco.bice.cl/inversiones/aprende-a-invertir/conoce-tu-perfil/encuesta" target="_blank" rel="noreferrer">aquí</a>
          </>
        )}
        options={InvestmentProfiles.asRadioOptions<InvestmentFormValues>()}
        optionsDirection="vertical"
      />
      <RadioField<InvestmentFormValues>
        path="has_usd_stocks"
        label="¿Tienes inversiones en dólares?"
        options={BooleanOptions.asRadioOptions('lowercase').reverse()}
        optionsDirection="vertical"
      />
      <RadioField<InvestmentFormValues>
        disabled={isLowNetWorth}
        path="wants_to_decide"
        label="¿Quieres tener un rol activo en la administración de tus inversiones?"
        options={BooleanOptions.asRadioOptions('lowercase').reverse()}
        optionsDirection="vertical"
        helpText={(
          <div className="text-white">
            Un rol activo se refiere a clientes que  quieran
            {' '}
            <span className="fw-bold">ser parte de la gestión de su inversión</span>
            , ya que su aprobación es necesaria para
            realizar cambios en el portafolio.
          </div>
        )}
      />

      <button
        disabled={!isFilled}
        className="btn btn-link align-self-start p-0 text-secondary"
        type="button"
        onClick={handleNavigateToAssetAdvisory}
      >
        Asesoría patrimonial
      </button>

      <ButtonLoading
        className="btn btn-secondary py-3"
        type={showObjectivesSection ? 'button' : 'submit'}
        disabled={!isFilled || form.formState.isValidating}
        isLoading={isFakeLoading}
        spinnerProps={{ size: 24 }}
        onClick={showObjectivesSection ? triggerFakeLoading : handleNextStep}
      >
        {showObjectivesSection ? 'Modificar datos' : 'Siguiente paso'}
      </ButtonLoading>
    </section>
  );
}
