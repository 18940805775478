import React from 'react';

export function LiquidityTable() {
  return (
    <div className="table-responsive my-n5">
      <table className="table-tiles table-tiles-radio">
        <thead>
          <tr>
            <td />
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Dimensión del portafolio</div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Objetivo de la inversión</div>
            </th>
            <th className="table-tile text-bg-info" scope="col" colSpan={3}>
              <div className="table-tile-divider">
                Estimación de escenarios
                <div className="d-flex justify-content-around mt-1">
                  <img src="/static/img/icons/sad-circle-white.svg" width="30" height="30" alt="Pesimista" />
                  <img src="/static/img/icons/expressionless-circle-white.svg" width="30" height="30" alt="Esperado" />
                  <img src="/static/img/icons/smile-circle-white.svg" width="30" height="30" alt="Optimista" />
                </div>
              </div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Plazo min. sugerido</div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              Riesgo de la inversión
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-tile-radio">
              {/* Checked by default because it does not represent any value in the form.
              This input is just decoration  */}
              <input className="form-check-input" type="radio" checked />
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Liquidez</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Necesidades de Liquidez</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">4,90%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider text-secondary">5,70%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">6,50%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Hasta 90 días</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="bg-info-light rounded-pill p-2 text-nowrap">
                Muy bajo
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
