/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps } from 'react';
import { LoadingSpinner } from './LoadingSpinner';

interface ButtonLoadingProps extends ComponentProps<'button'> {
  isLoading: boolean
  children: JSX.Element | string
  spinnerProps?: ComponentProps<typeof LoadingSpinner>
}

export function ButtonLoading({
  isLoading, children, spinnerProps = {}, ...buttonProps
}: ButtonLoadingProps) {
  return (
    <button
      {...buttonProps}
      className={buttonProps.className}
      disabled={isLoading || buttonProps.disabled}
    >
      {isLoading ? <LoadingSpinner {...spinnerProps} /> : children}
    </button>
  );
}
