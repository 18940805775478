import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { DocumentsUploadButton } from './DocumentsUploadButton';
import { DocumentsList } from './DocumentsList';
import { FILE_EXTENSION_ERROR, useUploadDocuments } from './useUploadDocuments';
import { ErrorView } from './ErrorView';
import { SinglePreviewModal } from './SinglePreviewModal';
import { MultiplePreviewModal } from './MultiplePreviewModal';
import { InvalidDocumentModal } from './InvalidDocumentModal';

export function DocumentsUploader() {
  const {
    handleRemoveFile,
    handleUploadFiles,
    handleChangeRut,
    handleOpenSinglePreview,
    handleCloseMultiplePreview,
    handleCloseSinglePreview,
    handleSubmit,
    handleSendDocuments,
    formErrors,
    fieldHasError,
    handleCloseErrorModal,
    uploadError,
    singlePreviewModal,
    multiplePreviewModal,
    uploadDocumentsForm,
    isDisabledForm,
    view,
    isLoadingUpload,
  } = useUploadDocuments();
  return (
    <section className="row justify-content-center gx-0">
      {view === 'uploading' && (
        <div className="col-md-10 col-lg-8 col-xl-6">
          <div className="card shadow-drop">
            <div className="card-body p-6">
              <div className="text-center mb-6">
                {/* TODO: src should be determined by storage url given by backend */}
                <img src="/static/img/illustrations/person-upload-window.svg" alt="" />
              </div>

              <p className="text-subtitle text-center mb-6">
                A continuación coloca
                {' '}
                <span className="fw-bold">RUT del cliente</span>
                {' '}
                y sube el archivo
                {' '}
                <span className="fw-bold">en formato PDF</span>
                .
              </p>

              <h1 className="fs-5 text-primary text-center mb-6">
                Carga de documentos
              </h1>

              <form onSubmit={handleSubmit}>
                <div className="form-floating mb-6">
                  <input
                    id="rutField"
                    onChange={handleChangeRut}
                    value={uploadDocumentsForm.clientRUT}
                    placeholder=""
                    className={clsx(
                      'form-control',
                      { 'is-invalid': fieldHasError('clientRUT') }
                    )}
                    aria-describedby={fieldHasError('clientRUT') ? 'rutFieldError' : undefined}
                    required
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="rutField">RUT del cliente</label>
                  {fieldHasError('clientRUT') && (
                    <ul id="rutFieldError" className="list-unstyled invalid-feedback mb-0">
                      {formErrors.clientRUT.map((error) => <li>{error}</li>)}
                    </ul>
                  )}
                </div>

                <div className="card shadow-sm mb-6">
                  <div className="card-body d-flex flex-column flex-md-row gap-2">
                    <img className="align-self-center" src="/static/img/illustrations/quality-check.svg" width={88} alt="" />
                    <ul className="fs-sm text-subtitle mb-0">
                      <li>
                        El peso máximo que soporta la carga es de
                        {' '}
                        <span className="fw-bold">10Mbps por cada archivo</span>
                        .
                      </li>
                      <li>
                        El nombre del archivo debe tener
                        {' '}
                        <span className="fw-bold">máximo 256 caracteres</span>
                        .
                      </li>
                      <li>
                        Puedes cargar un
                        {' '}
                        <span className="fw-bold">máximo de 10 archivos</span>
                        {' '}
                        al mismo tiempo.
                      </li>
                      <li>
                        El formato del archivo debe ser en
                        {' '}
                        <span className="fw-bold">PDF sin incluir imágenes</span>
                        .
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mb-6">
                  <DocumentsUploadButton handleUploadFiles={handleUploadFiles} />
                  <DocumentsList
                    documents={uploadDocumentsForm.documents}
                    handleOpenSinglePreview={handleOpenSinglePreview}
                    handleRemoveFile={handleRemoveFile}
                  />
                  {formErrors.documents.map((error) => {
                    let message: ReactNode | string = error;

                    if (error === FILE_EXTENSION_ERROR) {
                      message = (
                        <>
                          El archivo adjunto no es válido.
                          Recuerda que todos los documentos deben subirse
                          {' '}
                          <span className="fw-bold">sólo en formato PDF</span>
                          .
                        </>
                      );
                    }

                    return (
                      <InvalidDocumentModal
                        key={error}
                        message={message}
                        handleClose={() => handleCloseErrorModal(error)}
                      />
                    );
                  })}
                </div>

                <div
                  className="alert alert-info alert-dismissible fade show mb-6"
                  role="alert"
                >
                  <div>
                    <span className="fw-bold">Recuerda que los documentos que puedes firmar son:</span>
                    {' '}
                    SAAPV, Solicitud de inversión, Contrato APV Prime, Anexo Comisiones.
                    <br />
                    <span className="fw-bold">No puedes firmar:</span>
                    {' '}
                    PAC, Documentos para onboarding clientes, Solicitud de retiro.
                  </div>
                  <button className="btn-close" type="button" data-bs-dismiss="alert" aria-label="Cerrar" />
                </div>

                <div className="row justify-content-center gy-4">
                  <div className="col-md-6 col-lg-5">
                    <a className="btn btn-outline-primary w-100" href="/cms/signer">
                      Volver
                    </a>
                  </div>
                  <div className="col-md-6 col-lg-5">
                    <button
                      className="btn btn-primary w-100"
                      type="submit"
                      disabled={isDisabledForm}
                    >
                      Obtener link
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <SinglePreviewModal
            handleCloseSinglePreview={handleCloseSinglePreview}
            document={singlePreviewModal.document}
            isOpen={singlePreviewModal.isOpen}
          />

          <MultiplePreviewModal
            handleSendDocuments={handleSendDocuments}
            handleCloseMultiplePreview={handleCloseMultiplePreview}
            documents={multiplePreviewModal.documents}
            isOpen={multiplePreviewModal.isOpen}
            isLoadingUpload={isLoadingUpload}
          />
        </div>
      )}

      {view === 'error' && <ErrorView error={uploadError} />}
    </section>
  );
}
