/**
 * User handler for access to non-sensitive environment variables injected by backend through
 * base template
 */
interface UserContext {
  USER_ID: number,
  FULL_NAME: string
  IS_EXTERNAL: boolean,
  COMPANY: string,
  UF_VALUE: number
  UTM_VALUE: number
}

type UserContextKey = keyof UserContext;

const USER_CONTEXT_ELEMENT_ID = '#user_context';
const userContext = document.querySelector(USER_CONTEXT_ELEMENT_ID);

export const getUserContext = (key: UserContextKey) => {
  if (!userContext) return null;

  const parsedData = JSON.parse(userContext.textContent || '') as UserContext;

  return parsedData[key];
};

export function transformCLPToUF(clp: number) {
  return clp / Number(getUserContext('UF_VALUE'));
}

export function transformUTMToCLP(utm: number) {
  return utm * Number(getUserContext('UTM_VALUE'));
}
