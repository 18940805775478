/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  RiskFormValues, tableForObjective, TableForObjective, useRiskForm,
} from './useRiskForm';
import { getCSRF } from '../../../utils/forms';
import { InvestmentProfileTable } from './InvestmentProfileTable';
import { LiquidityTable } from './LiquidityTable';
import { LongevityTable } from './LongevityTable';
import { RetirementTable } from './RetirementTable';
import { LongevityObjectivesOptions } from '../../../utils/constants';

const renderDisclaimerContentForObjective: Record<TableForObjective, JSX.Element> = {
  LIQUIDITY: (
    <div>
      Los
      {' '}
      <span className="fw-bold">escenarios nominales</span>
      {' '}
      son calculados con un modelo estadístico básico, que considera
      las rentabilidades históricas de los fondos respectivos
    </div>
  ),
  LONGEVITY: (
    <div>
      Los
      {' '}
      <span className="fw-bold">escenarios nominales</span>
      {' '}
      son calculados con un modelo estadístico básico, que considera
      las rentabilidades históricas de los fondos respectivos
    </div>
  ),
  RETIREMENT: (
    <div>
      Los
      {' '}
      <span className="fw-bold">escenarios reales</span>
      {' '}
      son calculados con un modelo estadístico básico, que considera
      las rentabilidades históricas de los fondos respectivos
    </div>
  ),
  OTHER: (
    <div>
      Los
      {' '}
      <span className="fw-bold">escenarios son calculados con un modelo estadístico básico</span>
      , que considera las rentabilidades históricas de los fondos respectivos.
      Los resultados se muestran en una rentabilidad nominal anualizada
    </div>
  ),

};

const renderTableForObjective: Record<TableForObjective, JSX.Element> = {
  OTHER: <InvestmentProfileTable />,
  LIQUIDITY: <LiquidityTable />,
  LONGEVITY: <LongevityTable />,
  RETIREMENT: <RetirementTable />,
};

const renderLabelForObjective: Record<TableForObjective, string> = {
  OTHER: 'Por perfil de inversionista',
  LIQUIDITY: 'Liquidez',
  LONGEVITY: 'Longevidad',
  RETIREMENT: 'Jubilación',
};

function computeTableForObjective(objective: Exclude<TableForObjective, 'RETIREMENT'>, longevityObjective: string): TableForObjective {
  if (objective === 'LONGEVITY' && longevityObjective === LongevityObjectivesOptions.retirement) {
    return 'RETIREMENT';
  }

  return objective;
}
interface RiskFormProps {
  jsonObject: unknown
}

export function RiskForm(props: RiskFormProps) {
  const defaultValues = props.jsonObject as RiskFormValues;
  const { methods, handleSubmit } = useRiskForm(defaultValues);
  const { objective, longevity_objective, profile } = methods.watch();
  const { name: CSRFName, value: CSRFValue } = getCSRF();
  const [table, setTable] = useState<TableForObjective>(computeTableForObjective(
    defaultValues.objective,
    defaultValues.longevity_objective
  ));

  const resetOptionByObjective: Record<TableForObjective, () => void> = {
    LIQUIDITY: () => {
      methods.setValue('objective', 'LIQUIDITY');
      methods.setValue('longevity_objective', '');
    },
    LONGEVITY: () => {
      methods.setValue('objective', 'LONGEVITY');

      if (longevity_objective === LongevityObjectivesOptions.retirement) {
        methods.setValue('longevity_objective', LongevityObjectivesOptions.assetPreservation);
      } else {
        methods.setValue('longevity_objective', defaultValues.longevity_objective || LongevityObjectivesOptions.assetPreservation);
      }
    },
    RETIREMENT: () => {
      methods.setValue('objective', 'LONGEVITY');
      methods.setValue('longevity_objective', LongevityObjectivesOptions.retirement);
    },
    OTHER: () => {
      methods.setValue('objective', 'OTHER');
      methods.setValue('longevity_objective', '');
    },
  };

  const handleChangeOption = (tableOption: TableForObjective) => {
    resetOptionByObjective[tableOption]();
    methods.setValue('profile', defaultValues.profile);
    setTable(tableOption);
  };

  return (
    <FormProvider {...methods}>
      <form
        id="risk-recommender-form"
        onSubmit={methods.handleSubmit(handleSubmit)}
        method="post"
        className="mb-6"
      >
        <input type="hidden" name={CSRFName} value={CSRFValue} />
        <input type="hidden" name="profile" value={profile} />
        <input type="hidden" name="longevity_objective" value={longevity_objective} />
        <input type="hidden" name="objective" value={objective} />

        <h2 className="fs-base text-subtitle mb-5">
          Según tu selección anterior, te conviene:
        </h2>
        {renderTableForObjective[table]}
      </form>

      <div className="ms-7 alert alert-info fs-base mb-6" role="alert">
        {renderDisclaimerContentForObjective[table]}
      </div>

      <h2 className="fs-base text-subtitle mb-6">Modifica tu objetivo:</h2>
      <div className="row g-4">
        {tableForObjective
          .filter((option) => option !== table)
          .map((option) => (
            <div key={option} className="col-lg-3">
              <button
                type="button"
                className="card card-shortcut w-100 h-100 p-0"
                onClick={() => handleChangeOption(option)}
              >
                <span className="card-body fw-bold text-subtitle p-4">
                  {renderLabelForObjective[option]}
                </span>
              </button>
            </div>
          ))}
      </div>
    </FormProvider>
  );
}
