import React from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { NumericFormatField, RadioField } from '../../Forms/Fields';
import { ExtraordinaryContribution, ExtraordinaryContributionOptions } from '../../../utils/constants';
import { transformCLPToUF, transformUTMToCLP } from '../../../utils/user';

const MAX_REGIME_A_BENEFIT_IN_UTM = 6;
const MAX_REGIME_A_BENEFIT = transformCLPToUF(
  transformUTMToCLP(MAX_REGIME_A_BENEFIT_IN_UTM)
); // In CLF ("UF")
const MAX_YEARLY_SAVINGS = 600; // In CLF ("UF")
const MAX_AGREED_DEPOSIT = 900; // In CLF ("UF")

export const extraordinaryContributionSchema = z.object({
  initial_contribution_kind: z.enum([
    ExtraordinaryContributionOptions.UNIQUE,
    ExtraordinaryContributionOptions.ANNUAL,
  ]),
  initial_contribution: z.number(),
  agreed_deposits_kind: z.enum([
    ExtraordinaryContributionOptions.UNIQUE,
    ExtraordinaryContributionOptions.ANNUAL,
  ]),
  agreed_deposits: z.number(),
});

type SchemaWithExtraordinaryContribution = {
  monthly_savings?: number;
  agreed_premium?: number;
  apv_regime?: string;
  regime?: string;
  extraordinary_contribution: z.infer<typeof extraordinaryContributionSchema>;
};

interface ExtraordinaryContributionFieldsProps<T extends SchemaWithExtraordinaryContribution> {
  id: string
  uniqueContributionKind: Path<T>
  uniqueContributionValue: Path<T>
  agreedDepositKind: Path<T>
  agreedDepositValue: Path<T>
}

export function ExtraordinaryContributionFields<T extends SchemaWithExtraordinaryContribution>({
  id,
  uniqueContributionKind,
  uniqueContributionValue,
  agreedDepositKind,
  agreedDepositValue,
}: ExtraordinaryContributionFieldsProps<T>) {
  const form = useFormContext<T>();

  const {
    monthly_savings,
    agreed_premium = 0,
    apv_regime,
    regime,
    extraordinary_contribution: {
      initial_contribution,
      agreed_deposits,
    },
  } = form.watch();
  const apvRegime = apv_regime ?? regime;

  const renderUniqueContributionHelpText = () => {
    if (!apvRegime) return undefined;

    const yearlySavingsWithoutContribution = monthly_savings !== undefined
      ? transformCLPToUF(monthly_savings * 12)
      : agreed_premium * 12;
    const yearlySavings = yearlySavingsWithoutContribution + transformCLPToUF(initial_contribution);

    const isRegimeA = apvRegime === 'A';
    const exceedsLimit = isRegimeA
      ? yearlySavings * 0.15 > MAX_REGIME_A_BENEFIT
      : yearlySavings > MAX_YEARLY_SAVINGS;

    if (!exceedsLimit) return undefined;

    const limitText = isRegimeA
      ? (
        <>
          El monto no puede superar
          {' '}
          <span className="fw-bold">la bonificación fiscal del 15%</span>
          {' '}
          sobre el monto ahorrado con un tope de
          {' '}
          <span className="fw-bold">
            {MAX_REGIME_A_BENEFIT_IN_UTM}
            {' '}
            UTM al año
          </span>
          .
        </>
      )
      : (
        <>
          Para Régimen B el beneficio tributario tiene un tope de
          {' '}
          <span className="fw-bold">
            hasta
            {' '}
            {MAX_YEARLY_SAVINGS}
            {' '}
            UF anuales
          </span>
          .
        </>
      );

    return (
      <section>
        <header>
          <h6 className="fw-bold text-white mb-0">¡Atención!</h6>
        </header>
        <p className="mb-0">{limitText}</p>
      </section>
    );
  };

  const exceedsMaxDeposits = transformCLPToUF(agreed_deposits) > MAX_AGREED_DEPOSIT;

  return (
    <>
      <RadioField<T>
        label="Aporte directo (DAPV)"
        helpText={renderUniqueContributionHelpText()}
        path={uniqueContributionKind}
        options={ExtraordinaryContribution.asRadioOptions()}
      />

      <NumericFormatField<T>
        id={`${id}-unique-contribution-value`}
        path={uniqueContributionValue}
        prefix="$ "
        placeholder="$"
      />

      <RadioField<T>
        label="Depósito convenido"
        helpText={exceedsMaxDeposits ? (
          <section>
            <header>
              <h6 className="fw-bold text-white mb-0">¡Atención!</h6>
            </header>

            <p className="mb-0">
              Los Depósitos Convenidos tienen un tope anual de
              {' '}
              <span className="fw-bold">
                beneficio tributario de
                {' '}
                {MAX_AGREED_DEPOSIT}
                {' '}
                UF
              </span>
              .
            </p>
          </section>
        ) : undefined}
        path={agreedDepositKind}
        options={ExtraordinaryContribution.asRadioOptions()}
      />

      <NumericFormatField<T>
        id={`${id}-agreed-deposit-value`}
        path={agreedDepositValue}
        prefix="$ "
        placeholder="$"
      />
    </>
  );
}
