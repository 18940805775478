import React from 'react';
import {
  Cell, Pie, PieChart, ResponsiveContainer, Tooltip,
} from 'recharts';
import { themeColors } from './common';
import { renderPieChartLabel } from './utils';
import { JsonPropsType } from '../../component-loader';
import { ChartCard } from './components/ChartCard';

interface DonutChartProps {
  financialMarketsPercent: number;
  alternativeAssetsPercent: number;
  realEstateAssetsPercent: number;
  otherAssetsPercent: number;
}

function DonutChart({
  alternativeAssetsPercent,
  financialMarketsPercent,
  otherAssetsPercent,
  realEstateAssetsPercent,
}: DonutChartProps) {
  const data = [
    {
      name: 'Otros Activos',
      value: realEstateAssetsPercent,
      color: themeColors.darkSecondary,
    },

    {
      name: 'Activos Inmobiliarios',
      value: otherAssetsPercent,
      color: themeColors.info,
    },
    {
      name: 'Activos Alternativos',
      value: financialMarketsPercent,
      color: themeColors.secondary,
    },
    {
      name: 'Mercados Financieros',
      value: alternativeAssetsPercent,
      color: themeColors.primary,
    },

  ];
  return (
    <div className="row g-4 align-items-center mb-0">
      <div className="col-7 col-lg-8 col-xl-7">
        <ResponsiveContainer width="100%" height="100%" minHeight={180}>
          <PieChart>
            <Pie
              isAnimationActive={false}
              blendStroke
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={70}
              dataKey="value"
              label={renderPieChartLabel}
              labelLine={false}
            >
              {data.map((entry) => (
                <Cell key={`cell-${entry.name}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <ul className="col-5 col-lg-4 col-xl-5 d-flex flex-column gap-2">
        {data.map((entry) => (
          <li
            key={entry.name}
            style={{
              color: entry.color,
              textAlign: 'start',
            }}
          >
            <span className="text-dark fs-xs">
              {entry.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

type AssetDistributionChartProps = {
  financialMarketsPercent: number;
  alternativeAssetsPercent: number;
  realEstateAssetsPercent: number;
  otherAssetsPercent: number;
  isRecommendation?: boolean;
};

export function AssetDistributionChart(props: JsonPropsType) {
  const {
    alternativeAssetsPercent,
    financialMarketsPercent,
    otherAssetsPercent,
    realEstateAssetsPercent,
    isRecommendation,
  } = props.jsonObject as AssetDistributionChartProps;

  return (
    <ChartCard cardBodyClassName="py-5 fs-sm text-primary text-center">
      <DonutChart
        alternativeAssetsPercent={alternativeAssetsPercent}
        financialMarketsPercent={financialMarketsPercent}
        otherAssetsPercent={otherAssetsPercent}
        realEstateAssetsPercent={realEstateAssetsPercent}
      />

      <h3 className="fw-normal fs-sm text-primary mt-4 mb-0">
        {'Composición de '}
        <span className="fw-bold">activos</span>
      </h3>

      {isRecommendation && (
        <>
          <hr className="border-gray-blue-100 mx-auto" style={{ width: 240 }} />
          <p className="mb-0">
            Según tu perfil de inversionista y la información proporcionada,
            los porcentajes sugeridos corresponden a una
            {' '}
            <span className="fw-bold">recomendación sobre la distribución de activos</span>
            .
          </p>
        </>
      )}
    </ChartCard>
  );
}
