/* eslint-disable no-void */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { Accordion } from 'react-bootstrap';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';

import { ApvLifeSimulationValues, apvLifeValidationSchema, useApvLife } from './useApvLife';
import {
  CheckboxField,
  DatepickerField,
  InputField,
  NumericFormatField,
  RadioCardField,
  RadioField,
  SliderField,
} from '../../Forms/Fields';
import {
  BooleanOptions,
  CompensationPlan,
  ExtraCompensationInsured,
  ExtraCompensationInsuredOptions,
  GenderOptions,
  Genders,
  ApvTransferOptions,
  Regime,
  RegimeOptions,
  Regimes,
  SavingsTypeOptions,
  StringifiedBooleans,
  GenderOptionType,
} from '../../../utils/constants';
import { getParameterContext } from '../../../utils/parameters';
import { Chip } from '../../common/Chip';
import { useRegimeRecommendation } from '../../../hooks/useRegimeRecommendation';
import { useMinimumCapital } from '../../../hooks/useMinimumCapital';
import {
  validateHasIdealPension,
  validateHasRetirementAge,
  validateAPVTransfer,
  validateSavingsOption,
  validateHasAgreedPremium,
  MIN_RETIREMENT_AGE_BY_GENDER,
} from '../../../utils/validations';
import { useResetAPVTransfer } from '../../../hooks/useResetAPVTransfer';
import { RegimeHelpPopover } from '../../common/Popover';
import { APVTransferField } from '../sharedFields/APVTransferField';
import { handleRutChange } from '../../../utils/formHandlers';
import { mapQueryParamsToRecommendationPayload } from '../../RecommenderForms/common/queryParams';
import { RadioBoxField } from '../../Forms/Fields/RadioBoxField';
import { transformCLPToUF } from '../../../utils/user';
import { SavingsExceededModal } from '../../SavingsExceededModal';
import { useSavingsExceededModal } from '../../SavingsExceededModal/useSavingsExceededModal';
import { ExtraordinaryContributionFields } from '../sharedFields/ExtraordinaryContribution';
import { getYearsDiffFromNow } from '../../../utils/dates';

function checkClientDataAccordionFilled() {
  const form = useFormContext<ApvLifeSimulationValues>();
  const { client } = form.watch();
  const {
    rut,
    date_of_birth,
    gender,
    net_income,
    afp_balance,
    apv_transfer_option,
    contribution_amount,
    source_transfer,
    savings_option,
    ideal_pension,
  } = client;

  return validateSavingsOption(savings_option, 'ideal_pension', ideal_pension)
  && validateAPVTransfer(
    apv_transfer_option, contribution_amount, source_transfer?.value
  )
  && [rut, date_of_birth, gender, net_income, afp_balance].every(Boolean);
}

function checkSavingsAccordionFilled() {
  const form = useFormContext<ApvLifeSimulationValues>();
  const { savings, client } = form.watch();
  const { savings_option } = client;
  const {
    agreed_premium,
    compensation,
    death_insured_capital,
    projection_rate,
  } = savings;

  return validateHasAgreedPremium(savings_option, agreed_premium)
  && [
    compensation,
    death_insured_capital,
    projection_rate,
  ]
    .every(Boolean);
}

interface ClientDataAccordionProps {
  handleGetRecommendedRegime: (net_income: number) => void
}

function ClientDataAccordion({ handleGetRecommendedRegime }: ClientDataAccordionProps) {
  const {
    watch, setValue, clearErrors, resetField,
  } = useFormContext<ApvLifeSimulationValues>();
  const enableRetirementAge = getParameterContext('ENABLE_RETIREMENT_AGE');
  const [exceedsMaxAgeByGender, setExceedsMaxAgeByGender] = useState(false);

  const {
    net_income,
    date_of_birth,
    gender,
    contribution_amount,
    apv_transfer_option,
    source_transfer,
    savings_option,
    ideal_pension,
    change_retirement_age,
    retirement_age,
    rut,
  } = watch('client') ?? {};

  useResetAPVTransfer<ApvLifeSimulationValues>({
    contributionOption: apv_transfer_option,
    contributionAmountPath: 'client.contribution_amount',
    transferCompanyPath: 'client.source_transfer',
    setValue,
  });

  const isFilled = validateAPVTransfer(
    apv_transfer_option,
    contribution_amount,
    source_transfer?.value
  )
    && validateHasIdealPension(savings_option, ideal_pension)
    && validateHasRetirementAge(change_retirement_age, retirement_age)
    && [net_income, date_of_birth, gender, rut].every(Boolean);

  useEffect(() => {
    if (savings_option !== 'ideal_pension') {
      setValue('client.ideal_pension', 0);
    }
  }, [savings_option]);

  useEffect(() => {
    clearErrors('client.date_of_birth');
  }, [gender, clearErrors]);

  useEffect(() => {
    if (change_retirement_age === 'FALSE') {
      resetField('client.retirement_age');
    }
  }, [change_retirement_age]);

  // Displays change retirement field when user provides a date of birth which exceeds
  // the default retirement age by gender
  useEffect(() => {
    const YEARS_THRESHOLD = 110;
    const currentAge = getYearsDiffFromNow(date_of_birth);

    if (
      !Number.isNaN(currentAge)
      && currentAge > MIN_RETIREMENT_AGE_BY_GENDER[gender as GenderOptionType]
      && currentAge <= YEARS_THRESHOLD) {
      setExceedsMaxAgeByGender(true);
      setValue('client.change_retirement_age', 'TRUE');
      return;
    }

    setExceedsMaxAgeByGender(false);
    setValue('client.change_retirement_age', 'FALSE');
  }, [gender, date_of_birth]);

  // Clears minimum retirement age error when user changes the gender input.
  // The idea is not to keep the stale error for a new selected gender;
  // so if gender changes, error is cleared
  useEffect(() => {
    clearErrors('client.retirement_age');
  }, [gender]);

  return (
    <Accordion.Item className="shadow-none" eventKey="0">
      <Accordion.Button className={clsx(isFilled && 'bg-success-light')}>
        Datos del cliente
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <RadioBoxField<ApvLifeSimulationValues>
          options={SavingsTypeOptions.asRadioBoxOptions({ select: ['savings_capacity', 'ideal_pension'] })}
          path="client.savings_option"
          label="¿Cómo te gustaría ahorrar?"
        />

        <InputField<ApvLifeSimulationValues>
          id="rut"
          label="¿Cuál es su RUT?"
          path="client.rut"
          type="text"
          placeholder="11.111.111-1"
          onChange={handleRutChange}
        />

        {
          savings_option === 'ideal_pension' && (
          <NumericFormatField<ApvLifeSimulationValues>
            id="ideal_pension"
            label="¿Cuál es tu pensión ideal? (monto bruto)"
            path="client.ideal_pension"
            prefix="$ "
            placeholder="$"
          />
          )
        }

        <DatepickerField<ApvLifeSimulationValues>
          id="date_of_birth"
          label="¿Cuál es tu fecha de nacimiento?"
          path="client.date_of_birth"
        />

        {
          enableRetirementAge && (
            <>
              <RadioField<ApvLifeSimulationValues>
                label="¿Quieres cambiar tu edad de jubilación?"
                path="client.change_retirement_age"
                options={BooleanOptions.asRadioOptions()}
              />
              {
                change_retirement_age === 'TRUE' && (
                  <NumericFormatField<ApvLifeSimulationValues>
                    id="retirement_age"
                    path="client.retirement_age"
                    placeholder="Ingresa la edad"
                    suffix=" años"
                    helpMessage={exceedsMaxAgeByGender ? 'Supera la edad de jubilación por defecto. Especifica otra edad.' : undefined}
                  />
                )
              }
            </>
          )
        }

        <RadioField<ApvLifeSimulationValues>
          label="¿Cuál es tu género?"
          options={Genders.asRadioOptions({ omit: ['OTHER'] })}
          path="client.gender"
        />

        <NumericFormatField<ApvLifeSimulationValues>
          id="net_income"
          label="¿Cuál es tu sueldo líquido?"
          path="client.net_income"
          prefix="$ "
          placeholder="$"
          onBlur={(value) => handleGetRecommendedRegime(value as number)}
        />

        <NumericFormatField<ApvLifeSimulationValues>
          id="afp_balance"
          label="¿Cuál es tu saldo en AFP? (Cuenta obligatoria)"
          path="client.afp_balance"
          prefix="$ "
          placeholder="$"
        />

        <APVTransferField<ApvLifeSimulationValues>
          contributionOption={apv_transfer_option}
          setValue={setValue}
          contributionAmountPath="client.contribution_amount"
          contributionOptionPath="client.apv_transfer_option"
          transferCompanyPath="client.source_transfer"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}

interface SavingsDataAccordionProps {
  setMinimumCapital: Dispatch<SetStateAction<number>>
  handleShowSavingsExceededModal: () => void
}

function SavingsDataAccordion({
  setMinimumCapital,
  handleShowSavingsExceededModal,
}: SavingsDataAccordionProps) {
  const { watch, setValue, clearErrors } = useFormContext<ApvLifeSimulationValues>();
  const {
    date_of_birth,
    gender,
    savings_option,
  } = watch('client') ?? {};

  const {
    death_insured_capital,
    extra_compensation_insured,
  } = watch('savings');

  const minimumDeathCapital = useMinimumCapital(
    {
      accidental_death: extra_compensation_insured.accidental_death,
      itp: extra_compensation_insured.itp,
      serious_illness_percentage: 0, // APV Life does not provide `serious illness percentage` input
    },
    {
      date_of_birth,
      gender: gender as keyof typeof GenderOptions,
      product: 'APV_LIFE',
    }
  );

  const canOpen = checkClientDataAccordionFilled();
  const isFilled = checkSavingsAccordionFilled();

  useEffect(() => {
    setMinimumCapital(minimumDeathCapital);
  }, [minimumDeathCapital]);

  useEffect(() => {
    if (savings_option !== 'savings_capacity') {
      setValue('savings.agreed_premium', 0);
    }
  }, [savings_option]);

  useEffect(() => {
    if (death_insured_capital >= minimumDeathCapital) {
      clearErrors('savings.death_insured_capital');
    }
  }, [death_insured_capital, minimumDeathCapital]);

  return (
    <Accordion.Item className="shadow-none" eventKey="1">
      <Accordion.Button className={clsx(isFilled && 'bg-success-light')} disabled={!canOpen}>
        Datos del seguro
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <div>
          <div className="mb-2">
            <NumericFormatField<ApvLifeSimulationValues>
              hideErrorMessage
              id="projection_rate"
              label="Rentabilidad real"
              max={5}
              min={1}
              path="savings.projection_rate"
              suffix=" %"
            />
          </div>

          <SliderField<ApvLifeSimulationValues>
            max={5}
            min={1}
            step={0.5}
            path="savings.projection_rate"
          />
        </div>

        <RadioField<ApvLifeSimulationValues>
          label="¿Cuál es el plan de Indemnización?"
          options={CompensationPlan.asRadioOptions()}
          path="savings.compensation"
        />

        <NumericFormatField<ApvLifeSimulationValues>
          id="death_insured_capital"
          label="Capital asegurado de fallecimiento"
          path="savings.death_insured_capital"
          prefix="UF "
          placeholder="UF"
          helpMessage={minimumDeathCapital ? `El capital mínimo debería ser de UF ${minimumDeathCapital}.` : undefined}
        />

        <CheckboxField<ApvLifeSimulationValues, Extract<ExtraCompensationInsuredOptions, 'accidental_death' | 'itp'>>
          label="¿Agregas otro capital asegurado?"
          options={ExtraCompensationInsured.asCheckboxOptions({ options: ['accidental_death', 'itp'] })}
          pathsMap={{
            accidental_death: 'savings.extra_compensation_insured.accidental_death',
            itp: 'savings.extra_compensation_insured.itp',
          }}
          extraClassName="gap-5"
        />

        { savings_option === 'savings_capacity' && (
          <NumericFormatField<ApvLifeSimulationValues>
            id="agreed_premium"
            label="Prima convenida"
            path="savings.agreed_premium"
            prefix="UF "
            placeholder="UF"
            onBlur={handleShowSavingsExceededModal}
          />
        )}

      </Accordion.Body>
    </Accordion.Item>
  );
}

// TODO: remove linter warning when Extraordinary Contribution section is
// approved by the client and therefore rendered in the APV Life form.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ExtraordinaryContributionAccordion() {
  const canOpen = checkSavingsAccordionFilled();

  return (
    <Accordion.Item className="shadow-none" eventKey="2">
      <Accordion.Button disabled={!canOpen} className={clsx(canOpen && 'bg-success-light')}>
        Aportes extraordinarios (Opcional)
      </Accordion.Button>

      <Accordion.Body className="d-flex flex-column gap-2">
        <ExtraordinaryContributionFields<ApvLifeSimulationValues>
          id="apv-life-extraordinary-contribution"
          agreedDepositKind="extraordinary_contribution.agreed_deposits_kind"
          agreedDepositValue="extraordinary_contribution.agreed_deposits"
          uniqueContributionKind="extraordinary_contribution.initial_contribution_kind"
          uniqueContributionValue="extraordinary_contribution.initial_contribution"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}

interface RegimeRadioCardsProps {
  recommendedRegime?: keyof typeof RegimeOptions
}

function RegimeRadioCards({ recommendedRegime }: RegimeRadioCardsProps) {
  return (
    <RadioCardField<ApvLifeSimulationValues>
      label="¿Bajo qué Régimen quieres invertir?"
      labelClassName="mb-3"
      options={Regimes.asRadioCardOptions()}
      path="regime"
      renderPopover={() => <RegimeHelpPopover />}
      cardWrapper={(card) => (
        <div className="position-relative w-100">
          {recommendedRegime && card.props.value === recommendedRegime && (
            <Chip
              text="Recomendado"
              icon={{ src: 'star-icon.svg', alt: 'star recommendation icon ' }}
              style={{
                width: 'max-content',
                position: 'absolute',
                zIndex: 1,
                top: -8,
                right: 0,
              }}
            />
          )}
          {card}
        </div>
      )}
    />
  );
}

export function ApvLifeSimulationForm() {
  const [minimumCapital, setMinimumCapital] = useState(0);
  const [repeatSimulation, setRepeatSimulation] = useState(false);
  const { handleSubmitSimulation } = useApvLife();

  // Load query parameters and tries to fill the default values based on them
  const searchParams = new URLSearchParams(window.location.search);
  const valuesFromRecommender = mapQueryParamsToRecommendationPayload(searchParams);

  const methods = useForm<ApvLifeSimulationValues>({
    defaultValues: {
      client: {
        gender: valuesFromRecommender.gender,
        net_income: valuesFromRecommender.net_income,
        date_of_birth: valuesFromRecommender.date_of_birth,
        change_retirement_age: StringifiedBooleans.FALSE,
        retirement_age: null,
        afp_balance: null,
        // Tries to get the non default option (ideal pension) from query parameters.
        // If it is not present, automatically select the default saving option (savings capacity)
        savings_option: valuesFromRecommender.ideal_pension > 0 ? 'ideal_pension' : 'savings_capacity',
        ideal_pension: valuesFromRecommender.ideal_pension,
        apv_transfer_option: valuesFromRecommender.additional_savings[0] === 'apv'
          ? ApvTransferOptions.TRANSFER
          : ApvTransferOptions.NONE,
        contribution_amount: 0,
        source_transfer: null,
      },
      savings: {
        agreed_premium: transformCLPToUF(valuesFromRecommender.monthly_savings),
        projection_rate: 1,
        compensation: '',
        extra_compensation_insured: {
          accidental_death: false,
          itp: false,
        },
      },
      extraordinary_contribution: {
        agreed_deposits_kind: 'UNIQUE',
        agreed_deposits: 0,
        initial_contribution_kind: 'UNIQUE',
        initial_contribution: 0,
      },
      regime: '',
    },
    resolver: zodResolver(apvLifeValidationSchema(minimumCapital)),
  });

  const handleValidateErrors = () => methods.formState.errors;

  const { client, regime, savings } = methods.watch();
  const {
    recommendedRegime,
    handleGetRecommendedRegime,
  } = useRegimeRecommendation(client.net_income);
  const {
    show: showSavingsExceededModal,
    handleShow: handleShowSavingsExceededModal,
    handleClose: handleCloseSavingsExceededModal,
  } = useSavingsExceededModal({
    currency: 'CLF',
    regime: regime as Regime,
    savingsAmount: savings.agreed_premium,
  });

  const onSubmit = async (data: ApvLifeSimulationValues) => {
    await handleSubmitSimulation(data);
    setRepeatSimulation(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="d-flex flex-column gap-5">
        <Accordion className="accordion-form accordion-pills" defaultActiveKey="0">
          <ClientDataAccordion handleGetRecommendedRegime={handleGetRecommendedRegime} />
          <SavingsDataAccordion
            handleShowSavingsExceededModal={handleShowSavingsExceededModal}
            setMinimumCapital={setMinimumCapital}
          />
          {/*
            TODO: uncomment this section when Extraordinary Contribution is approved
            in order to render accordion and fields.
          */}
          {/* <ExtraordinaryContributionAccordion /> */}
        </Accordion>

        <RegimeRadioCards recommendedRegime={recommendedRegime} />

        <button onClick={handleValidateErrors} type="submit" className="btn btn-secondary text-white py-3 w-100 fw-bold">
          {repeatSimulation ? 'Volver a simular' : 'Simular'}
        </button>
      </form>

      <SavingsExceededModal
        isOpen={showSavingsExceededModal}
        handleClose={handleCloseSavingsExceededModal}
      />
    </FormProvider>
  );
}
