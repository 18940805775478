/* eslint-disable @typescript-eslint/no-floating-promises */
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { validateLongevityObjective } from '../../../utils/validations';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { injectCleanedFieldToForm } from '../../../utils/forms';

export const assetAdvisoryFormSchema = z.object({
  clp_capital_markets_investment: z.number().nonnegative(),
  usd_capital_markets_investment: z.number().nonnegative(),
  clp_alt_assets: z.number().nonnegative(),
  usd_alt_assets: z.number().nonnegative(),
  apv: z.number().nonnegative(),
  other_investments: z.number().nonnegative(),

  clp_pension_plan: z.number().nonnegative(),
  usd_pension_plan: z.number().nonnegative(),
  clp_real_state_assets: z.number().nonnegative(),
  usd_real_state_assets: z.number().nonnegative(),
  private_company_stakes: z.number().nonnegative(),

  mortgage_credit: z.number().nonnegative(),
  children_education: z.number().nonnegative(),
  current_consumer_credit: z.number().nonnegative(),
  future_real_state_funds: z.number().nonnegative(),
  other_obligations: z.number().nonnegative(),

  incomes: z.number().nonnegative(),
  real_state_rent: z.number().nonnegative(),
  financial_products: z.number().nonnegative(),
  wants_sell_actives: z.string(),
  other_incomes: z.number().nonnegative(),

  objective: z.string(),
  longevity_objective: z.string(),
}).refine(({ objective, longevity_objective }) => (
  validateLongevityObjective(objective, longevity_objective)
), {
  path: ['longevity_objective'],
  message: VALIDATION_MESSAGES.REQUIRED_FIELD,
});

export type AssetAdvisoryFormValues = z.infer<typeof assetAdvisoryFormSchema>;

const ASSETS_SECTION_FIELDS: Array<keyof AssetAdvisoryFormValues> = [
  'clp_capital_markets_investment',
  'usd_capital_markets_investment',
  'clp_alt_assets',
  'usd_alt_assets',
  'apv',
  'other_investments',
  'clp_pension_plan',
  'usd_pension_plan',
  'clp_real_state_assets',
  'usd_real_state_assets',
  'private_company_stakes',
  'mortgage_credit',
  'children_education',
  'current_consumer_credit',
  'other_obligations',
  'future_real_state_funds',
  'other_obligations',
  'incomes',
  'real_state_rent',
  'financial_products',
  'wants_sell_actives',
  'other_incomes',
];

export function useAssetAdvisoryForm(defaultValues: AssetAdvisoryFormValues) {
  const methods = useForm<AssetAdvisoryFormValues>({
    resolver: zodResolver(assetAdvisoryFormSchema),
    defaultValues: {
      ...defaultValues,
      wants_sell_actives: defaultValues.wants_sell_actives !== null
        ? String(defaultValues.wants_sell_actives) : undefined,
    },
  });
  const [showObjectivesSection, setShowObjectivesSection] = useState(false);

  const handleAssetsSectionNextStep = async () => {
    try {
      const isClientSectionValid = await methods.trigger(ASSETS_SECTION_FIELDS);

      if (isClientSectionValid && !showObjectivesSection) {
        setShowObjectivesSection(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Notice basically all fields are currency fields; so we need to clean the
  // stringified currency value (eg. $10.000.000) and transform it into a base numeric string
  // (10000000) to be able to be processed by backend.
  const injectCleanedValues = (form: HTMLFormElement) => {
    const values = methods.getValues();
    Object
      .entries(values)
      .forEach(([key, value]) => injectCleanedFieldToForm({
        form,
        value,
        name: key,
      }));
  };

  const handleSubmit: SubmitHandler<AssetAdvisoryFormValues> = (data, event) => {
    const form = event?.target as HTMLFormElement;
    injectCleanedValues(form);
    form.submit();
  };

  useEffect(() => {
    const verifyFieldsOnPreload = async () => {
      const isAssetsSectionValid = await methods.trigger(ASSETS_SECTION_FIELDS);
      methods.clearErrors(ASSETS_SECTION_FIELDS);

      if (isAssetsSectionValid) {
        setShowObjectivesSection(true);
      }
    };

    verifyFieldsOnPreload();
  }, []);

  return {
    methods,
    showObjectivesSection,
    handleSubmit,
    handleAssetsSectionNextStep,
    injectCleanedValues,
  };
}

export function useAssetAdvisoryAccordions() {
  const { watch } = useFormContext<AssetAdvisoryFormValues>();

  const {
    apv,
    clp_alt_assets,
    clp_capital_markets_investment,
    other_investments,
    usd_alt_assets,
    usd_capital_markets_investment,
    clp_pension_plan,
    clp_real_state_assets,
    private_company_stakes,
    usd_pension_plan,
    usd_real_state_assets,
    current_consumer_credit,
    future_real_state_funds,
    mortgage_credit,
    other_obligations,
    financial_products,
    incomes,
    other_incomes,
    real_state_rent,
    wants_sell_actives,
    children_education,
  } = watch();

  const isMarketInvestmentsAccordionFilled = [
    apv,
    clp_alt_assets,
    clp_capital_markets_investment,
    other_investments,
    usd_alt_assets,
    usd_capital_markets_investment,
  ].every((v) => v !== null);

  const isOtherAssetsAccordionFilled = [
    clp_pension_plan,
    clp_real_state_assets,
    private_company_stakes,
    usd_pension_plan,
    usd_real_state_assets,
  ].every((v) => v !== null);

  const isObligationsAccordionFilled = [
    current_consumer_credit,
    future_real_state_funds,
    children_education,
    mortgage_credit,
    other_obligations,
  ].every((v) => v !== null);

  const isIncomesAccordionFilled = [
    financial_products,
    incomes,
    other_incomes,
    real_state_rent,
    wants_sell_actives,
  ].every((v) => v !== null);

  const areAllAccordionsFilled = isMarketInvestmentsAccordionFilled
    && isOtherAssetsAccordionFilled
    && isObligationsAccordionFilled
    && isIncomesAccordionFilled;

  return {
    isMarketInvestmentsAccordionFilled,
    isOtherAssetsAccordionFilled,
    isObligationsAccordionFilled,
    isIncomesAccordionFilled,
    areAllAccordionsFilled,
  };
}
