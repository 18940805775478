import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

export function ObligationsAccordion() {
  const {
    isMarketInvestmentsAccordionFilled,
    isObligationsAccordionFilled,
    isOtherAssetsAccordionFilled,
  } = useAssetAdvisoryAccordions();

  const canOpen = isMarketInvestmentsAccordionFilled && isOtherAssetsAccordionFilled;

  const [show, setShow] = useState(false);

  return (
    <Accordion.Item className="shadow-none" eventKey="2">
      <Accordion.Button
        className={clsx(isObligationsAccordionFilled && 'bg-success-light')}
        disabled={!canOpen}
      >
        {`Obligaciones ${show ? '(Considerar por 4 años)' : ''}`}
      </Accordion.Button>

      <Accordion.Body
        onEntering={() => setShow(true)}
        onExiting={() => setShow(false)}
        className="d-flex flex-column gap-2"
      >
        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="mortgage_credit"
          path="mortgage_credit"
          label="¿Tienes alguna obligación de Crédito Hipotecario?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="children_education"
          path="children_education"
          label="¿Tienes obligaciones por la educación de tus hijos?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="current_consumer_credit"
          path="current_consumer_credit"
          label="¿Tienes un crédito de consumo vigente?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="future_real_state_funds"
          path="future_real_state_funds"
          label="¿Cuánto planeas invertir en comprar en el corto plazo alguna propiedad, terreno o similar?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="other_obligations"
          path="other_obligations"
          label="¿Hay alguna otra obligación que no te haya preguntado previamente?"
          prefix="$ "
          placeholder="$"
          helpPopover={{
            id: 'other-obligations-help-popover',
            width: 362,
            renderContent: () => (
              <div className="d-flex align-items-start gap-2 p-1">
                <img src="/static/img/icons/help-light.svg" width={48} alt="" />

                <section>
                  <header>
                    <h6 className="fs-base fw-bold text-white mb-1">Otras obligaciones</h6>
                  </header>

                  <div className="text-white">
                    Aquí puedes preguntar por leasing, actividades como independiente entre otros.
                  </div>
                </section>
              </div>
            ),
          }}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
