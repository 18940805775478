/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { AdvisorySection } from './AdvisorySection';
import { AssetAdvisoryFormValues, useAssetAdvisoryForm } from './useAssetAdvisoryForm';
import { ObjectivesSection, ObjectivesSectionPlaceholder } from '../common/components/ObjectivesSection';
import { getCSRF } from '../../../utils/forms';
import { createRecommendationValidatedEvent } from '../common/events';
import { JsonPropsType } from '../../../component-loader';

interface AssetAdvisoryFormProps {
  default_values: AssetAdvisoryFormValues
  back_url: string
}

export function AssetAdvisoryForm(props: JsonPropsType) {
  const {
    back_url,
    default_values,
  } = props.jsonObject as AssetAdvisoryFormProps;
  const {
    methods,
    handleAssetsSectionNextStep,
    showObjectivesSection,
    handleSubmit,
    injectCleanedValues,
  } = useAssetAdvisoryForm(default_values);
  const { objective } = methods.watch();

  const { name: CSRFName, value: CSRFValue } = getCSRF();
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    window.dispatchEvent(createRecommendationValidatedEvent('asset_advisory', methods.formState.isValid && Boolean(objective)));
  }, [methods.formState, objective]);

  return (
    <FormProvider {...methods}>
      <form
        id="asset-advisory-form"
        className="row justify-content-center g-5"
        method="post"
        onSubmit={methods.handleSubmit(handleSubmit)}
        ref={formRef}
      >
        <input type="hidden" name={CSRFName} value={CSRFValue} />
        <input type="hidden" name="objective" value="OTHER" />
        <input type="hidden" name="longevity_objective" value="" />
        <input type="hidden" name="action" value="" />

        <div className="col-lg-5 col-2xl-4">
          <AdvisorySection
            showObjectivesSection={showObjectivesSection}
            handleNextStep={handleAssetsSectionNextStep}
            backUrl={back_url}
          />
        </div>

        <div className="col-lg-7 col-2xl-6">
          {showObjectivesSection
            ? (
              <ObjectivesSection
                injectCleanedValues={injectCleanedValues}
                formRef={formRef}
              />
            ) : <ObjectivesSectionPlaceholder />}
        </div>
      </form>
    </FormProvider>
  );
}
