import React from 'react';
import { Accordion } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { MarketInvestmentsAccordion } from './MarketInvestmentsAccordion';
import { OtherAssetsAccordion } from './OtherAssetsAccordion';
import { ObligationsAccordion } from './ObligationsAccordion';
import { IncomesAccordion } from './IncomesAccordion';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';

interface AdvisorySectionProps {
  handleNextStep: () => void
  showObjectivesSection: boolean
  backUrl: string
}

export function AdvisorySection({
  handleNextStep,
  showObjectivesSection,
  backUrl,
}: AdvisorySectionProps) {
  const { areAllAccordionsFilled } = useAssetAdvisoryAccordions();
  const form = useFormContext<AssetAdvisoryFormValues>();

  return (
    <section className="card-form">
      <Accordion className="accordion-form accordion-pills" defaultActiveKey="0">
        <MarketInvestmentsAccordion />
        <OtherAssetsAccordion />
        <ObligationsAccordion />
        <IncomesAccordion />
      </Accordion>

      <a
        href={backUrl}
        className="btn btn-link align-self-start p-0 text-secondary"
      >
        Asesoría simplificada
      </a>

      <button
        className="btn btn-secondary py-3"
        type={showObjectivesSection ? 'button' : 'submit'}
        disabled={!areAllAccordionsFilled || form.formState.isValidating}
        onClick={showObjectivesSection ? undefined : handleNextStep}
      >
        {showObjectivesSection ? 'Modificar datos' : 'Siguiente paso'}
      </button>
    </section>
  );
}
