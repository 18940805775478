/* eslint-disable @typescript-eslint/no-floating-promises */
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { requiredString } from '../../../utils/commonSchemaValidators';
import {
  validateAgeMayority,
  validateAssetManagementByNetWorth,
  validateLongevityObjective,
  validateMaxAge,
} from '../../../utils/validations';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { formatMoney } from '../../../utils/formatters';
import { injectCleanedFieldToForm } from '../../../utils/forms';

export const MIN_NET_WORTH_FOR_ASSET_MANAGEMENT = 150_000_000;
const MIN_NET_WORTH = 0;
const MAX_AGE = 99;

export const investmentFormSchema = z.object({
  name: requiredString(),
  date_of_birth: requiredString()
    .refine((date_of_birth) => validateAgeMayority(date_of_birth), { message: 'El cliente debe ser mayor de edad ' })
    .refine((date_of_birth) => validateMaxAge({ date: date_of_birth, maxAge: MAX_AGE }), { message: `La edad máxima permitida es de ${MAX_AGE} años.` }),
  has_children: requiredString(),
  net_worth: z.number()
    .min(MIN_NET_WORTH, `El valor no debe ser menor a ${formatMoney(MIN_NET_WORTH)}`)
    .refine((amount) => amount !== 0, { message: VALIDATION_MESSAGES.REQUIRED_FIELD }),
  profile: requiredString(),
  has_usd_stocks: requiredString(),
  wants_to_decide: z.string().nullable().optional(),
  objective: z.string(),
  longevity_objective: z.string(),
}).refine(({ objective, longevity_objective }) => (
  validateLongevityObjective(objective, longevity_objective)
), {
  path: ['longevity_objective'],
  message: VALIDATION_MESSAGES.REQUIRED_FIELD,
}).refine(({ net_worth, wants_to_decide }) => validateAssetManagementByNetWorth(
  net_worth,
  MIN_NET_WORTH_FOR_ASSET_MANAGEMENT,
  wants_to_decide
), {
  path: ['wants_to_decide'],
  message: VALIDATION_MESSAGES.REQUIRED_FIELD,
});

export type InvestmentFormValues = z.infer<typeof investmentFormSchema>;

export const CLIENT_SECTION_FIELDS: Array<keyof InvestmentFormValues> = [
  'name',
  'date_of_birth',
  'has_children',
  'net_worth',
  'profile',
  'has_usd_stocks',
  'wants_to_decide',
];

export const OBJECTIVES_SECTION_FIELDS: Array<keyof InvestmentFormValues> = [
  'objective',
  'longevity_objective',
];

function isEditingRecommendationSession(defaultValues: InvestmentFormValues) {
  return Boolean(defaultValues.date_of_birth);
}

const COMES_FROM_ASSET_ADVISORY_KEY = 'COME_FROM_ASSET_ADVISORY';

function verifyComesFromAssetAdvisory() {
  return JSON.parse(localStorage.getItem(COMES_FROM_ASSET_ADVISORY_KEY) || 'false') as boolean;
}

export function saveOnComesFromAssetAdvisory() {
  localStorage.setItem(COMES_FROM_ASSET_ADVISORY_KEY, 'true');
}

export function useInvestmentForm(defaultValues: InvestmentFormValues) {
  const isEdit = isEditingRecommendationSession(defaultValues);

  const {
    has_children, has_usd_stocks, wants_to_decide,
  } = defaultValues;

  const methods = useForm<InvestmentFormValues>({
    resolver: zodResolver(investmentFormSchema),
    mode: isEdit ? 'onChange' : 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultValues,
      has_children: has_children !== null ? String(has_children) : undefined,
      has_usd_stocks: has_usd_stocks !== null ? String(has_usd_stocks) : undefined,
      wants_to_decide: wants_to_decide !== null ? String(wants_to_decide) : undefined,
    },
  });

  const { net_worth } = methods.watch();
  const [showObjectivesSection, setShowObjectivesSection] = useState(false);

  const handleSubmit: SubmitHandler<InvestmentFormValues> = (data, event) => {
    const form = event?.target as HTMLFormElement;
    injectCleanedFieldToForm({ form, value: data.net_worth, name: 'net_worth' });
    form.submit();
  };
  const injectCleanedValues = (form: HTMLFormElement) => {
    injectCleanedFieldToForm({ form, name: 'net_worth', value: net_worth });
  };

  const handleClientSectionNextStep = async () => {
    try {
      const isClientSectionValid = await methods.trigger(CLIENT_SECTION_FIELDS);

      if (isClientSectionValid && !showObjectivesSection) {
        setShowObjectivesSection(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const validateNetWorthOnChange = async () => methods.trigger('net_worth');

    if (isEdit) {
      validateNetWorthOnChange();
    }
  }, [net_worth, isEdit]);

  useEffect(() => {
    const verifyFieldsOnPreload = async () => {
      const isClientSectionValid = await methods.trigger(CLIENT_SECTION_FIELDS);
      methods.clearErrors(CLIENT_SECTION_FIELDS);

      const comesFromAssetAdvisory = verifyComesFromAssetAdvisory();

      if (isClientSectionValid && !comesFromAssetAdvisory) {
        setShowObjectivesSection(true);
      }

      if (comesFromAssetAdvisory) {
        localStorage.removeItem(COMES_FROM_ASSET_ADVISORY_KEY);
      }
    };

    verifyFieldsOnPreload();
  }, []);

  return {
    methods,
    handleSubmit,
    handleClientSectionNextStep,
    showObjectivesSection,
    injectCleanedValues,
  };
}
