import React, { PropsWithChildren } from 'react';

interface ChartCardProps extends PropsWithChildren {
  headerText?: string
  textLink?: string
  url?: string
  isDownload?: boolean
  cardBodyClassName?: string
}

export function ChartCard({
  children, headerText, textLink, url, isDownload, cardBodyClassName,
}: ChartCardProps) {
  return (
    <section className="card border border-gray-200 shadow-sm h-100">
      <div className={`card-body ${cardBodyClassName ?? 'px-5'}`}>
        {headerText && (
          <header>
            <h4 className="mb-0 text-center fw-normal fs-lg text-primary">
              {headerText}
            </h4>
          </header>
        )}

        {children}

        {textLink && url && !isDownload && (
          <footer className="mt-5 text-center">
            <a href={url} className="btn btn-link" target="_blank" rel="noreferrer">{textLink}</a>
          </footer>
        )}
      </div>
    </section>
  );
}
