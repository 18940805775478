import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import clsx from 'clsx';
import { NumericFormatField, RadioField } from '../../Forms/Fields';
import { AssetAdvisoryFormValues, useAssetAdvisoryAccordions } from './useAssetAdvisoryForm';
import { BooleanOptions } from '../../../utils/constants';

export function IncomesAccordion() {
  const {
    isIncomesAccordionFilled,
    isMarketInvestmentsAccordionFilled,
    isObligationsAccordionFilled,
    isOtherAssetsAccordionFilled,
  } = useAssetAdvisoryAccordions();
  const canOpen = isMarketInvestmentsAccordionFilled
  && isOtherAssetsAccordionFilled
  && isObligationsAccordionFilled;
  const [show, setShow] = useState(false);

  return (
    <Accordion.Item className="shadow-none" eventKey="3">
      <Accordion.Button
        className={clsx(isIncomesAccordionFilled && 'bg-success-light')}
        disabled={!canOpen}
      >
        {`Ingresos ${show ? '(Considerar por 4 años)' : ''}`}
      </Accordion.Button>

      <Accordion.Body
        onEntering={() => setShow(true)}
        onExiting={() => setShow(false)}
        className="d-flex flex-column gap-2"
      >
        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="incomes"
          path="incomes"
          label="¿Con cuánto cuentas de ingresos como dependiente o independiente?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="real_state_rent"
          path="real_state_rent"
          label="¿Con cuánto cuentas en alguna renta inmobiliaria?"
          prefix="$ "
          placeholder="$"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="financial_products"
          path="financial_products"
          label="¿Con cuánto cuentas en ingresos por instrumentos financieros líquidos?"
          prefix="$ "
          placeholder="$"
        />

        <RadioField<AssetAdvisoryFormValues>
          path="wants_sell_actives"
          options={BooleanOptions.asRadioOptions('lowercase').reverse()}
          label="¿Planeas vender alguna propiedad u otro tipo de activo?"
          optionsDirection="vertical"
        />

        <NumericFormatField<AssetAdvisoryFormValues>
          displayZeroOnEmpty
          id="other_incomes"
          path="other_incomes"
          label="¿Hay algún otro ingreso que no te haya preguntado previamente?"
          prefix="$ "
          placeholder="$"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
