import { z } from 'zod';
import { useEffect } from 'react';
import { formatMoney } from '../../../utils/formatters';
import {
  forceRetirementAgeByBirthdate,
  validateAgeMayority,
  validateHasRetirementAge,
  validateAPVTransfer,
  validateMinimumRetirementAge,
  validateRetirementAgeAgainstBirthdate,
  validateRutByRegexp,
  MIN_RETIREMENT_AGE_BY_GENDER,
} from '../../../utils/validations';
import { BaseSimulationParams, WithExtraordinaryContribution, WithAPVTransfer } from '../../../interfaces/simulations';
import { simulationManager } from '../../../utils/simulationsManager';
import {
  GenderOptionType, ApvTransferOptions, SavingsOption,
  StringifiedBooleans,
} from '../../../utils/constants';
import { injectSimulationIdToInfograph } from '../../../behaviors/infograhps/handleInfographDownload';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { EVENTS, tag } from '../../../behaviors/cms/gtm-inputs';
import { useSavingsCapacitySuggestion } from '../../../hooks/useSavingsCapacitySuggestion';
import { selectOption } from '../../../utils/commonSchemaValidators';
import { extraordinaryContributionSchema } from '../sharedFields/ExtraordinaryContribution';

const MIN_AMOUNT = 0;

export const provisionalSavingsValidationSchema = z.object({
  savings_option: z.enum([SavingsOption.ideal_pension, SavingsOption.savings_capacity]),
  rut: z.string()
    .refine((rut) => validateRutByRegexp(rut), { message: VALIDATION_MESSAGES.INVALID_RUT }),
  ideal_pension:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  monthly_savings:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  date_of_birth:
    z.string()
      .nonempty(VALIDATION_MESSAGES.REQUIRED_FIELD)
      .refine((date_of_birth) => validateAgeMayority(date_of_birth), 'El cliente debe ser mayor de edad'),
  change_retirement_age:
    z.enum([StringifiedBooleans.TRUE, StringifiedBooleans.FALSE]),
  retirement_age:
    z.number().nullable(),
  gender:
    z.string()
      .nonempty(VALIDATION_MESSAGES.REQUIRED_FIELD),
  net_salary:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  afp_balance:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  apv_transfer_option:
      z.enum([
        ApvTransferOptions.NONE,
        ApvTransferOptions.TRANSFER,
      ]),
  contribution_amount:
      z.number(),
  source_transfer:
      selectOption().nullable(),
  risk_profile:
    selectOption(),
  apv_regime:
    z.string()
      .nonempty('Este campo es requerido'),
  extraordinary_contribution: extraordinaryContributionSchema,
})

  .refine(({ net_salary, monthly_savings }) => net_salary > monthly_savings, {
    message: 'El ahorro no debe ser mayor que el sueldo líquido',
    path: ['monthly_savings'],
  })
  .refine(({
    apv_transfer_option,
    contribution_amount,
    source_transfer,
  }) => validateAPVTransfer(
    apv_transfer_option,
    contribution_amount,
    source_transfer?.value),
  {
    message: 'La información de traspaso es requerida',
    path: ['apv_transfer_option'],
  })
  .refine(({
    change_retirement_age,
    retirement_age,
  }) => validateHasRetirementAge(change_retirement_age, retirement_age),
  {
    message: 'Debes indicar una edad de retiro',
    path: ['retirement_age'],
  })
  .refine(({ date_of_birth, gender, change_retirement_age }) => forceRetirementAgeByBirthdate(
    date_of_birth,
    gender as GenderOptionType,
    change_retirement_age
  ), {
    message: 'Debes especificar una edad de jubilación',
    path: ['change_retirement_age'],
  })
  .refine(({ retirement_age, date_of_birth }) => validateRetirementAgeAgainstBirthdate(
    retirement_age,
    date_of_birth
  ), {
    message: 'La edad de jubilación no puede ser menor a la edad del cliente',
    path: ['retirement_age'],
  })
  .refine(({ retirement_age, gender }) => validateMinimumRetirementAge(
    retirement_age,
    gender as GenderOptionType
  ),
  ({ gender }) => ({
    message: `La edad mínima de jubilación es de ${MIN_RETIREMENT_AGE_BY_GENDER[gender as GenderOptionType] ?? ''} años`,
    path: ['retirement_age'],
  }));

export type ProvisionalSavingsSimulationValues =
  z.infer<typeof provisionalSavingsValidationSchema>;

interface ProvisionalSavingsPayload extends
  BaseSimulationParams,
  WithExtraordinaryContribution,
  WithAPVTransfer {
  rut: string
  ideal_pension: number
  monthly_savings: number
  date_of_birth: string
  gender: string
  net_salary: number
  afp_balance: number
  risk_profile: string
  apv_regime: string
  savings_option: string
  initial_contribution: number
  apv_transfer_origin: string
  apv_transfer_amount: number
  expected_retirement: number | null
}

export function useProvisionalSavings() {
  const {
    isSuccess: isSubmitted,
    mutate: dispatchSavingsCapacitySuggestion,
    data: savingsSuggestionResponse,
  } = useSavingsCapacitySuggestion();

  const mapFormToPayload = (values: ProvisionalSavingsSimulationValues) => {
    const {
      rut,
      ideal_pension,
      monthly_savings,
      afp_balance,
      apv_regime,
      date_of_birth,
      gender,
      net_salary,
      risk_profile,
      contribution_amount,
      savings_option,
      source_transfer,
      apv_transfer_option,
      retirement_age,
      extraordinary_contribution,
    } = values;

    const payload: ProvisionalSavingsPayload = {
      rut,
      ideal_pension,
      monthly_savings,
      afp_balance,
      apv_regime,
      date_of_birth,
      gender,
      net_salary,
      expected_retirement: retirement_age,
      // `apv_transfer` fields are dedicated to transfer contribution option
      apv_transfer_origin: source_transfer && apv_transfer_option === 'TRANSFER' ? source_transfer?.value : '',
      apv_transfer_amount: apv_transfer_option === 'TRANSFER' ? contribution_amount : 0,
      savings_option,
      risk_profile: risk_profile.value,

      // Extraordinary contribution
      agreed_deposits_kind: extraordinary_contribution.agreed_deposits_kind,
      agreed_deposits: extraordinary_contribution.agreed_deposits,
      initial_contribution_kind: extraordinary_contribution.initial_contribution_kind,
      initial_contribution: extraordinary_contribution.initial_contribution,

      simulation_type: 'PROVISIONAL_SAVINGS',
      wording: 'CLIENT',
    };

    return payload;
  };

  const handleSubmitSimulation = async (values: ProvisionalSavingsSimulationValues) => {
    try {
      const payload = mapFormToPayload(values);
      dispatchSavingsCapacitySuggestion(payload);

      simulationManager.render<ProvisionalSavingsPayload>({
        srcOptions: {
          parameters: payload,
        },
        iframeAttributes: {
          classNames: ['provisional-savings', SavingsOption[values.savings_option]],
          title: 'provisional-savings-simulation',
        },
        parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
      });

      await injectSimulationIdToInfograph(payload, 'APV_START');

      tag({
        event: EVENTS.SUBMIT,
        variables: {
          id: 'create_lead_from_apv',
        },
      });

      if (values.savings_option === 'ideal_pension') {
        tag({
          event: EVENTS.BUTTON,
          variables: {
            id: 'btn_pension_ideal',
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderEmptySimulation = () => {
    simulationManager.render<BaseSimulationParams>({
      srcOptions: {
        parameters: {
          wording: 'CLIENT',
          simulation_type: 'PROVISIONAL_SAVINGS',
          is_empty: true,
        },
      },
      iframeAttributes: {
        classNames: ['provisional-savings', 'empty'],
        title: 'provisional-savings-simulation',
      },
      parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
    });
  };

  useEffect(() => {
    renderEmptySimulation();
  }, []);

  return {
    handleSubmitSimulation,
    isSubmitted,
    optionalIncomeReduction: savingsSuggestionResponse?.optional_income_reduction ?? 0,
  };
}
