import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Radio } from '../../Forms/Fields/RadioField';
import { RiskFormValues } from './useRiskForm';
import { LongevityObjectivesOptions } from '../../../utils/constants';

export function LongevityTable() {
  const { setValue } = useFormContext<RiskFormValues>();

  const setLongevityObjectiveValue = (value: string) => setValue('longevity_objective', value);

  return (
    <div className="table-responsive my-n5">
      <table className="table-tiles table-tiles-radio">
        <thead>
          <tr>
            <td />
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Dimensión del portafolio</div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Objetivo de la inversión</div>
            </th>
            <th className="table-tile text-bg-info" scope="col" colSpan={3}>
              <div className="table-tile-divider">
                Estimación de escenarios
                <div className="d-flex justify-content-around mt-1">
                  <img src="/static/img/icons/sad-circle-white.svg" width="30" height="30" alt="Pesimista" />
                  <img src="/static/img/icons/expressionless-circle-white.svg" width="30" height="30" alt="Esperado" />
                  <img src="/static/img/icons/smile-circle-white.svg" width="30" height="30" alt="Optimista" />
                </div>
              </div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Plazo min. sugerido</div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              Riesgo de la inversión
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            onClick={() => setLongevityObjectiveValue(LongevityObjectivesOptions.assetPreservation)}
          >
            <td className="table-tile-radio">
              <Radio<RiskFormValues>
                id={`longevity_objective-${LongevityObjectivesOptions.assetPreservation}`}
                path="longevity_objective"
                value={LongevityObjectivesOptions.assetPreservation}
                aria-label="Preservación patrimonial"
              />
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Longevidad</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">
                Preservación patrimonial
              </div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">3,80%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider text-secondary">7,62%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">11,44%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Entre 91 y 365 días</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="bg-info-light rounded-pill p-2 text-nowrap">
                Bajo
              </div>
            </td>
          </tr>
          <tr
            onClick={() => setLongevityObjectiveValue(LongevityObjectivesOptions.assetAccumulation)}
          >
            <td className="table-tile-radio">
              <Radio<RiskFormValues>
                id={`longevity_objective-${LongevityObjectivesOptions.assetAccumulation}`}
                path="longevity_objective"
                value={LongevityObjectivesOptions.assetAccumulation}
                aria-label="Acumulación patrimonial"
              />
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Longevidad</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Acumulación patrimonial</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">1,07%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider text-secondary">8,44%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">15,82%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Sobre 1 año</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="bg-warning-light rounded-pill p-2 text-nowrap">
                Medio - Alto
              </div>
            </td>
          </tr>
          <tr onClick={() => setLongevityObjectiveValue(LongevityObjectivesOptions.assetGrowth)}>
            <td className="table-tile-radio">
              <Radio<RiskFormValues>
                id={`longevity_objective-${LongevityObjectivesOptions.assetGrowth}`}
                path="longevity_objective"
                value={LongevityObjectivesOptions.assetGrowth}
                aria-label="Crecimiento patrimonial"
              />
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Longevidad</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Crecimiento patrimonial</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">-1,49%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider text-secondary">8,91%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">19,30%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Sobre 2 años</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="bg-danger-light rounded-pill p-2 text-nowrap">
                Alto
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
